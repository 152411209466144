.channel-header__container {
  height: 95px;
  display: flex;
  flex-direction: column;
  background: #089aa8;
  border-radius: 32px 32px 0px 0px;
}

.channel-header__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 20px 5px;
  height: 60px;
}

.channel-header__heading img {
  background-blend-mode: normal;
  border: 2px solid #089aa8;
  box-sizing: border-box;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.08));
  border-radius: 32px;
}

.channel-header__image-3 {
  position: absolute;
  z-index: 1;
  top: 20px;
}

.channel-header__image-2 {
  position: absolute;
  left: 40px;
  top: 20px;
  z-index: 2;
}

.channel-header__image-1 {
  position: absolute;
  left: 60px;
  top: 20px;
  z-index: 3;
}

.channel-header__active {
  position: relative;
  left: 88px;
  top: 19px;
  width: 16px;
  height: 16px;
  background: #20e070;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.channel-header__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.channel-header__name {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  color: #ffffff;
  height: 32px;
  margin: 0;
  margin-bottom: 5px;
}

.channel-header__subtitle {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  height: 18px;
  margin: 0;
  color: #ffffff;
}

.channel-header__wait__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.channel-header__wait__text {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  margin-left: 5px;
}
