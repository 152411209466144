.agent-channel-list {
  background: #233746;
  display: flex;
  flex-direction: column;
}

.agent-channel-list__message {
  color: #ffffff;
  background: #233746;
  padding: 0 16px;
  height: 100%;
}
